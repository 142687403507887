import React from 'react';
import './index.scss';
import {ShortEventFileDTO, WishlistedEventFilesResponseDTO} from "@/shared/api/gen/data-contracts";
import TempPhotoMaterial from "@/shared/ui/eventMaterials/TempPhotoMaterial/TempPhotoMaterial";

type Props = {
    photos: ShortEventFileDTO[];
    handleClick: (index: number) => void;
    refresh: () => void;
}

const PhotosGrid = ({photos, handleClick, refresh}: Props) => {
    return (
        <div className='EventsMaterials_photo mt-16'>
            {photos?.map((photo, index) => {
                return (
                    <TempPhotoMaterial
                        className='EventsMaterials_photo__item'
                        index={index}
                        photo={photo}
                        handleClick={handleClick}
                        refresh={refresh}
                        onLoad={(
                            ref: React.RefObject<HTMLDivElement>,
                            e: React.SyntheticEvent<HTMLImageElement, Event>
                        ) => {
                            if (e?.target?.naturalWidth > e?.target?.naturalHeight) {
                                ref?.current?.classList?.add('landscape');
                            }
                        }}
                    />
                );
            })}
        </div>
    );
};

export default PhotosGrid;