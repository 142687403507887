import React, {SetStateAction, useState} from 'react';
import './index.scss';
import BackgroundAnimation from "@/shared/ui/animations/BackgroundAnimation/BackgroundAnimation";
import { motion } from 'framer-motion';
import close from "public/images/close.png";
import CustomButton, {ButtonVariant} from "@/shared/ui/buttons/Button";
import {FlexRow} from "@/shared/ui/layouts/Flex/Flex";
import RatingEmoji from "@/shared/ui/RatingEmoji/RatingEmoji";
import emoji1 from 'public/images/Emoji1.png';
import emoji2 from 'public/images/Emoji2.png';
import emoji3 from 'public/images/Emoji3.png';
import emoji4 from 'public/images/Emoji4.png';
import emoji5 from 'public/images/Emoji5.png';
import api from "@/shared/api/api";

type Props = {
    setVisible: React.Dispatch<SetStateAction<boolean>>;
    eventId: number;
}

export enum Emoji {
    EMOJI1,
    EMOJI2,
    EMOJI3,
    EMOJI4,
    EMOJI5
}
const FeedbackModal = ({setVisible, eventId}: Props) => {
    const [activeEmoji, setActiveEmoji] = useState<Emoji>();
    const [comment, setComment] = useState<string>('');
    const sendFeedback = () => {
        api.events
            .createEventUserFeedbackApiEventsEventIdFeedbackPost(eventId, {comment: comment, rating: activeEmoji})
            .catch((res) => alert(`Произошла ошибка со статус кодом ${res.status}`))
            .then(() => setVisible(false));
    }
    const onEnterClick = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event?.key === 'Enter') {
            // @ts-ignore
            event?.target?.blur();
        }
    };
    return (
        <>
            <BackgroundAnimation setVisible={setVisible}/>
            <motion.div
                style={{
                    display: 'flex',
                    position: 'fixed',
                    left: '0px',
                    bottom: '0px',
                    width: '100%',
                    zIndex: 15,
                }}
                initial={{y: '100svh'}}
                animate={{y: '0'}}
                exit={{y: '100svh'}}
                transition={{ease: 'easeInOut', duration: 0.5}}
            >
                <div className='FeedbackModal'>
                    <FlexRow style={{justifyContent: 'space-between', gap: '16px', alignItems: 'flex-start'}}>
                        <h1 className='text-22 medium'>Поделитесь вашим мнением о прошедшем мероприятии</h1>
                        <img className='FeedbackModal_close'
                             src={close}
                             alt='close'
                             onClick={() => setVisible(false)}
                        />
                    </FlexRow>
                    <h2 className='text-18 medium mt-24'>Понравилось ли вам мероприятие?</h2>
                    <div className='FeedbackModal_rating mt-16'>
                        <RatingEmoji
                            emoji={Emoji.EMOJI1}
                            icon={emoji1}
                            active={activeEmoji}
                            setActive={setActiveEmoji}
                        />
                        <RatingEmoji
                            emoji={Emoji.EMOJI2}
                            icon={emoji2}
                            active={activeEmoji}
                            setActive={setActiveEmoji}
                        />
                        <RatingEmoji
                            emoji={Emoji.EMOJI3}
                            icon={emoji3}
                            active={activeEmoji}
                            setActive={setActiveEmoji}
                        />
                        <RatingEmoji
                            emoji={Emoji.EMOJI4}
                            icon={emoji4}
                            active={activeEmoji}
                            setActive={setActiveEmoji}
                        />
                        <RatingEmoji
                            emoji={Emoji.EMOJI5}
                            icon={emoji5}
                            active={activeEmoji}
                            setActive={setActiveEmoji}
                        />
                    </div>
                    <textarea
                        className='mt-16'
                        placeholder='Опишите здесь ваш опыт и пожелания по улучшению'
                        maxLength={200}
                        onChange={(event) => setComment(event.target.value)}
                        onKeyDown={(event) => onEnterClick(event)}
                    />
                    <CustomButton
                        theme={ButtonVariant.orange}
                        onClick={sendFeedback}
                        className='mt-24'
                    >
                        <span className='text-18 medium'>Отправить оценку!</span>
                    </CustomButton>
                </div>
            </motion.div>
        </>
    );
};

export default FeedbackModal;