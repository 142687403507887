import React from 'react';
import './index.scss';
import CustomButton, {ButtonVariant} from "@/shared/ui/buttons/Button";
import tgLogo from 'public/images/TelegramLogo.png';
import DropdownList from "@/shared/ui/DropdownList/DropdownList";
import {FlexColumn} from "@/shared/ui/layouts/Flex/Flex";
import {SupportQuestions} from "@/mock/SupportQuestions";

const SupportPage = () => {
    return (
        <div className='SupportPage invisibleScroll'>
            <CustomButton
                className='g-12'
                theme={ButtonVariant.reverse}
                style={{borderRadius: '10px'}}
            >
                <img
                    src={tgLogo}
                    alt='logo'
                    style={{width: '24px', height: '24px'}}
                />
                <span className='text-18 medium'>Написать в Telegram</span>
            </CustomButton>
            <h1 className='text-22 medium mt-32'>Часто задаваемые вопросы</h1>
            <FlexColumn className='g-8 mt-16'>
                <DropdownList
                    question={SupportQuestions[0].question}
                    list={SupportQuestions[0].list}
                />
                <DropdownList
                    question={SupportQuestions[1].question}
                    list={SupportQuestions[1].list}
                />
                <DropdownList
                    question={SupportQuestions[2].question}
                    list={SupportQuestions[2].list}
                />
            </FlexColumn>
        </div>
    );
};

export default SupportPage;