import {ISupportQuestion} from "@/entities/ISupportQuestion";

export const SupportQuestions: ISupportQuestion[] = [
    {
        question: 'Как зарегистрироваться на мероприятие?',
        list: [
            '- Откройте страницу выбранного мероприятия.',
            '- Нажмите кнопку «Зарегистрироваться»',
            '- После успешной регистрации вы получите QR-код для прохода на мероприятие и электронный билет'
        ]
    },
    {
        question: 'Могу ли я отменить регистрацию',
        list: [
            'На данный момент эта функция недоступна'
        ]
    },
    {
        question: 'Можно ли поделиться мероприятием с друзьями?',
        list: [
            '- Откройте страницу выбранного мероприятия.',
            '- Нажмите кнопку «Зарегистрироваться»',
            '- После успешной регистрации вы получите QR-код для прохода на мероприятие и электронный билет'
        ]
    }
]