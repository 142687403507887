import React from 'react';
import arrowUp from "public/images/ArrowUp.png";
import {FlexRow} from "@/shared/ui/layouts/Flex/Flex";
import {WishlistedEventFilesResponseDTO} from "@/shared/api/gen/data-contracts";
import {useNavigate} from "react-router-dom";

type Props = {
    photoGroup: WishlistedEventFilesResponseDTO;
}

const PhotosGridNavigate = ({photoGroup}: Props) => {
    const navigate = useNavigate();
    return (
        <FlexRow
            style={{justifyContent: 'space-between', alignItems: 'flex-start', gap: '8px'}}
            className='mt-16 mb-16'
            onClick={() => navigate(`/events/${photoGroup.id}`)}
        >
            <h1 className='text-18 medium'>{photoGroup.title}</h1>
            <img src={arrowUp}
                 style={{width: '24px', height: '24px'}}
                 alt='arrow'/>
        </FlexRow>
    );
};

export default PhotosGridNavigate;