import React from 'react';
import './index.scss';
import BookmarkImage from "@/widgets/BookmarkImage/BookmarkImage";
import DateChip from "@/shared/ui/DateChip/DateChip";
import {ServerApiEventsClientDtoEventsListResponseDTO} from "@/shared/api/gen/data-contracts";
import {useNavigate} from "react-router-dom";

type Props = {
    event: ServerApiEventsClientDtoEventsListResponseDTO;
    dateChipClassName?: string;
}

const EventCardWithDescription = ({event, dateChipClassName}: Props) => {
    const navigate = useNavigate();
    const date = new Date(event.start_at);
    const dateOptions: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
    };
    return (
        <div className='EventCardWithDescription'>
            <BookmarkImage
                event={event}
                onClick={() => navigate(`/events/${event?.id}`)}
            />
            <DateChip
                className={`EventCardWithDescription_date mt-8 ${dateChipClassName}`}>
                <span className='text-12 medium'>{date.toLocaleDateString('ru-RU', dateOptions)}</span>
            </DateChip>
            <h2 className='text-14 semibold mt-8'>{event?.title}</h2>
        </div>
    );
};

export default EventCardWithDescription;