import React, {useState} from 'react';
import './index.scss';
import arrowDown from 'public/images/arrow_down.png';
import {AnimatePresence, motion} from "framer-motion";

type Props = {
    question: string;
    list: string[];
}

const DropdownList = ({question, list}: Props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    return (
        <div className='DropdownList'>
            <div
                className={`DropdownList_question ${isExpanded && 'DropdownList_expanded'}`}
                onClick={() => setIsExpanded(prev => !prev)}
            >
                <h1 className='text-18 medium'>{question}</h1>
                <img src={arrowDown} alt='arrow'/>
            </div>
            <AnimatePresence>
                {isExpanded &&
                    <motion.div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            background: '#EBF0F6'
                        }}
                        initial={{height: '0px'}}
                        animate={{height: 'fit-content'}}
                        exit={{height: '0px'}}
                        transition={{ease: 'easeInOut', duration: 0.2}}
                    >
                        <hr style={{width: '100%', border: 'none', borderTop: '1px solid #DEE6F0', marginTop: '8px'}}/>
                        <ul>
                            {list.map(item => {
                                return <li className='text-16 regular'>{item}</li>
                            })}
                        </ul>
                    </motion.div>
                }
            </AnimatePresence>

        </div>

    );
};

export default DropdownList;