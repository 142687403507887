import React from 'react';
import './index.scss';
import bookmark from "public/images/bookmark.png";
import bookmarkActive from 'public/images/BookmarkActive.png';
import useFetch from "@/shared/hooks/useFetch";
import {ServerApiEventsClientDtoEventsListResponseDTO, UserWishListResponseDTO} from "@/shared/api/gen/data-contracts";
import api from "@/shared/api/api";
import {EnvConfig} from "@/app/config";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    event: ServerApiEventsClientDtoEventsListResponseDTO;
}
const BookmarkImage = ({event, ...props}: Props) => {
    const {data: wishlist, refresh: refreshWishlist} = useFetch<UserWishListResponseDTO[]>(
        () => api.users.getWishlistApiUsersWishlistGet(),
        [],
        []
    );
    const addToWishlist = () => {
        api.users.addToWishlistApiUsersWishlistEventRefPost(event.id)
            .then(() => refreshWishlist());
    }
    const deleteFromWishList = () => {
        api.users.removeFromWishlistApiUsersWishlistEventRefDelete(event.id)
            .then(() => refreshWishlist());
    }
    const handleWishlist = (e) => {
        e.stopPropagation();
        if (wishlist.some(item => item.event_ref == event.id )){
            deleteFromWishList();
        }
        else{
            addToWishlist();
        }
    }
    const {onClick} = props;
    return (
        <div className='BookmarkImage'>
            <img className='BookmarkImage_image'
                 src={`${EnvConfig.HOST_URL}/${event?.preview}`}
                 alt='image'
                 onClick={onClick}
            />
            <img className='BookmarkImage_bookmark'
                 src={(wishlist.some(item => item.event_ref == event?.id) ? bookmarkActive : bookmark)}
                 alt='bookmark'
                 onClick={(e) => handleWishlist(e)}
            />
        </div>
    );
};

export default BookmarkImage;