/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BodyUploadAvatarApiUploadsAvatarPost,
  BodyUploadEventFileApiUploadsEventFilesEventIdPost,
  BodyUploadFileApiUploadsGenericSourceInstanceIdPost,
  HTTPValidationError,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Uploads<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Uploads
   * @name UploadFileApiUploadsGenericSourceInstanceIdPost
   * @summary Upload File
   * @request POST:/api/uploads/generic/{source}/{instance_id}
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  uploadFileApiUploadsGenericSourceInstanceIdPost = (
    source: any,
    instanceId: number,
    data: BodyUploadFileApiUploadsGenericSourceInstanceIdPost,
    params: RequestParams = {},
  ) =>
    this.request<string, HTTPValidationError>({
      path: `/api/uploads/generic/${source}/${instanceId}`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Uploads
   * @name UploadAvatarApiUploadsAvatarPost
   * @summary Upload Avatar
   * @request POST:/api/uploads/avatar
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  uploadAvatarApiUploadsAvatarPost = (data: BodyUploadAvatarApiUploadsAvatarPost, params: RequestParams = {}) =>
    this.request<string, HTTPValidationError>({
      path: `/api/uploads/avatar`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Uploads
   * @name UploadEventFileApiUploadsEventFilesEventIdPost
   * @summary Upload Event File
   * @request POST:/api/uploads/event_files/{event_id}
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  uploadEventFileApiUploadsEventFilesEventIdPost = (
    eventId: number,
    data: BodyUploadEventFileApiUploadsEventFilesEventIdPost,
    params: RequestParams = {},
  ) =>
    this.request<string, HTTPValidationError>({
      path: `/api/uploads/event_files/${eventId}`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Uploads
   * @name DeleteEventFileApiUploadsEventFilesIdDelete
   * @summary Delete Event File
   * @request DELETE:/api/uploads/event_files/{_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  deleteEventFileApiUploadsEventFilesIdDelete = (id: number, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/uploads/event_files/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Uploads
   * @name GetS3FileApiUploadsS3FilenameGet
   * @summary Get S3 File
   * @request GET:/api/uploads/s3/{filename}
   * @response `200` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getS3FileApiUploadsS3FilenameGet = (filename: string, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/uploads/s3/${filename}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Uploads
   * @name GetMediaFileApiUploadsFilePathGet
   * @summary Get Media File
   * @request GET:/api/uploads/{file_path}
   * @response `200` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getMediaFileApiUploadsFilePathGet = (filePath: string, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/uploads/${filePath}`,
      method: "GET",
      ...params,
    });
}
