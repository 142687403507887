import React, { useRef } from 'react';
import './index.scss';
import bookmark from 'public/images/bookmark.png';
import bookmarkActive from 'public/images/BookmarkActive.png';
import {EnvConfig} from "@/app/config";
import {
	ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO,
	ShortEventFileDTO
} from "@/shared/api/gen/data-contracts";
import api from "@/shared/api/api";
import {instanceOf} from "prop-types";

type Props = {
	index: number;
	className?: string;
	photo: ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO;
	handleClick: (index: number) => void;
	onLoad?: (ref: any, e: React.SyntheticEvent<HTMLImageElement, Event>) => any;
	refresh: () => void;
};

const PhotoMaterial = ({ className, index, photo, handleClick, onLoad, refresh }: Props) => {
	const ref = useRef(null);
	const handleWishlist = (e) => {
		e.stopPropagation();
		if (photo.in_wishlist){
			api.events.removeEventFileFromWishlistApiEventsEventFilesWishlistFileIdDelete(photo.id)
				.then(() => refresh());
		}
		else{
			api.events.addEventFileToWishlistApiEventsEventFilesWishlistFileIdPost(photo.id)
				.then(() => refresh());
		}
	}
	return (
		<div
			ref={ref}
			className={`PhotoMaterial ${className || ''}`}
			onClick={() => handleClick(index)}>
			<img
				src={`${EnvConfig.HOST_URL}/api/uploads/s3/${photo?.file}`}
				alt='photo'
				onLoad={(e) => onLoad && onLoad(ref, e)}
			/>
			<div className='PhotoMaterial_bookmark'>
				<img
					src={photo.in_wishlist ? bookmarkActive : bookmark}
					alt='favourite'
					onClick={(e) => handleWishlist(e)}
				/>
			</div>

		</div>
	);
};
export default PhotoMaterial;
