import React from 'react';
import './index.scss';
import bookmark from 'public/images/bookmark.png';
import DateChip from '@/shared/ui/DateChip/DateChip';
import { useNavigate } from 'react-router-dom';
import {ServerApiEventsClientDtoEventsListResponseDTO, UserWishListResponseDTO} from '@/shared/api/gen/data-contracts';
import { getShortText } from '@/shared/utils/HOC/getShortText';
import api from "@/shared/api/api";
import bookmarkActive from 'public/images/BookmarkActive.png';
import {EnvConfig} from "@/app/config";

type Props = {
	event: ServerApiEventsClientDtoEventsListResponseDTO;
	wishlist: UserWishListResponseDTO[];
	refreshWishlist: () => void;
};

const EventCard = ({ event, wishlist, refreshWishlist }: Props) => {
	const navigate = useNavigate();
	const date = new Date(event.start_at);
	const dateOptions: Intl.DateTimeFormatOptions = {
		day: 'numeric',
		month: 'long',
		hour: '2-digit',
		minute: '2-digit',
	};
	const addToWishlist = () => {
		api.users.addToWishlistApiUsersWishlistEventRefPost(event.id)
			.then(() => refreshWishlist());
	}
	const deleteFromWishList = () => {
		api.users.removeFromWishlistApiUsersWishlistEventRefDelete(event.id)
			.then(() => refreshWishlist());
	}
	const handleWishlist = (e) => {
		e.stopPropagation();
		if (wishlist.some(item => item.event_ref == event.id )){
			deleteFromWishList();
		}
		else{
			addToWishlist();
		}
	}
	return (
		<div
			className='EventCard'
			onClick={() => navigate(`/events/${event?.id}`)}>
			{event?.preview && (
				<div className='EventCard_imageWrapper'>
					<img
						src={`${EnvConfig.HOST_URL}/${event?.preview}`}
						className='EventCard_imageWrapper_preview'
						alt='preview'
					/>
					<img
						src={(wishlist.some(item => item.event_ref == event.id) ? bookmarkActive : bookmark)}
						className='EventCard_imageWrapper_bookmark'
						alt='bookmark'
						onClick={(e) => handleWishlist(e)}
					/>
				</div>
			)}
			<DateChip className='mt-8'>
				<span className='text-16 medium'>{date.toLocaleDateString('ru-RU', dateOptions)}</span>
			</DateChip>
			<h1 className='text-16 medium mt-8'>{event.title}</h1>
			<h2 className='text-14 regular mt-3'>
				{event?.description && getShortText(event?.description, { maxLength: 100 }).text + '...'}
			</h2>
		</div>
	);
};

export default EventCard;
