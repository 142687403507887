import React, { SetStateAction } from 'react';
import './index.scss';
import { FlexRow } from '@/shared/ui/layouts/Flex/Flex';
import telegram from 'public/images/Telegram.png';
import favourite from 'public/images/Favourite.png';
import download from 'public/images/Import.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import { ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO } from '@/shared/api/gen/data-contracts';
import { EnvConfig } from '@/app/config';
import useTG from '@/shared/hooks/useTG';

type Props = {
	photos: ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO[];
	currentIndex: number;
	setCurrentIndex: React.Dispatch<SetStateAction<number>>;
};

const PhotoPage = ({ photos, currentIndex, setCurrentIndex }: Props) => {
	const { WebApp } = useTG({ requestWriteAccess: false });
	return (
		<div className='PhotoPage'>
			<FlexRow style={{ justifyContent: 'space-between', marginTop: '16px', padding: '0 16px 0 16px' }}>
				<div
					className='PhotoPage_download'
					onClick={() => {
						WebApp.downloadFile({
							url: `${EnvConfig.HOST_URL}/api/uploads/s3/${photos[currentIndex]?.file}`,
						});
					}}>
					<img
						src={download}
						alt='import'
					/>
					<h1 className='text-14 medium'>Скачать</h1>
				</div>
				<span className='text-18 medium'>{currentIndex + 1} из 1245</span>
				<FlexRow style={{ gap: '8px' }}>
					<div className='PhotoPage_iconWrapper'>
						<img
							src={telegram}
							alt='tg'
						/>
					</div>
					<div className='PhotoPage_iconWrapper'>
						<img
							src={favourite}
							alt='favourite'
						/>
					</div>
				</FlexRow>
			</FlexRow>
			<Swiper
				grabCursor={true}
				initialSlide={currentIndex}
				onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
				className='PhotoPage_swiper mt-16'>
				{photos?.map((photo, index) => {
					return (
						<SwiperSlide className='PhotoPage_swiperSlide'>
							<img
								src={`${EnvConfig.HOST_URL}/api/uploads/s3/${photo?.file}`}
								alt='photo'
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default PhotoPage;
