import React, {SetStateAction} from 'react';
import {MaterialsTabs} from "@/pages/EventMaterials";
import './index.scss';

export enum TabVariant {
    default = 'Tab',
    active = 'ActiveTab'
}
interface Props
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    text: MaterialsTabs;
    theme?: TabVariant;
    setActive: React.Dispatch<SetStateAction<MaterialsTabs>>;
}

const Tab = ({text, theme, setActive, ...props}: Props) => {
    return (
        <div className={`Tab ${props?.className} ${theme}`}
            onClick={() => setActive(text)}
        >
            {text}
        </div>
    );
};

export default Tab;