import React, { useState } from 'react';
import './index.scss';
import EventFilters from '@/widgets/EventFilters/EventFilters';
import EventCard from '@/widgets/EventCard/EventCard';
import mock from 'public/images/mock_preview.png';
import Navbar from '@/widgets/Navbar/Navbar';
import PageLayout from '@/shared/ui/layouts/PageLayout';
import useFetch from '@/shared/hooks/useFetch';
import {
	EventsListResponseDTO,
	ServerApiTagsClientDtoTagsListResponseDTO,
	TagGroupsListResponseDTO, UserWishListResponseDTO,
} from '@/shared/api/gen/data-contracts';
import api from '@/shared/api/api';
import ModalFilterInterests from '@/widgets/ModalFilterInterests/ModalFilterInterests';
import { AnimatePresence, motion } from 'framer-motion';
import CustomCalendar from '@/widgets/Calendar/Calendar';
import torus from 'public/images/Torus.png';

const EventList = () => {
	const [filterDates, setFilterDates] = useState<number[]>([]);
	const [filters, setFilters] = useState<number[]>([]);
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);
	const [currentTagGroup, setCurrentTagGroup] = useState<TagGroupsListResponseDTO>();
	const { data: events, loading: eventListLoading } = useFetch<EventsListResponseDTO[]>(
		() => api.events.getEventsApiEventsGet({ tag_refs: filters,
			start_in_range: filterDates.map(date => new Date(date).toISOString().split('T')[0]) }),
		[],
		[filters, filterDates]
	);
	const { data: tagGroups } = useFetch<TagGroupsListResponseDTO[]>(
		() => api.tagGroups.getTagGroupsApiTagGroupsGet(),
		[],
		[]
	);
	const { data: tags } = useFetch<ServerApiTagsClientDtoTagsListResponseDTO[]>(
		() => api.tags.getTagsApiTagsGet(),
		[],
		[]
	);
	const {data: wishlist, refresh: refreshWishlist} = useFetch<UserWishListResponseDTO[]>(
		() => api.users.getWishlistApiUsersWishlistGet(),
		[],
		[]
	);

	// NOTE: Чтобы избежать кривых состояний верстки во время загрузки данных. Сюда позже сможем добавить блок/страницу загрузки.
	if (eventListLoading) return null;
	return (
		<PageLayout navbar>
			<div className='EventList invisibleScroll'>
				<span className='text-22 medium mt-24 mb-16 px-16'>Все мероприятия</span>
				<EventFilters
					tagGroups={tagGroups}
					// TODO: Неверная типизация
					setCurrentTagGroup={setCurrentTagGroup}
					setIsVisible={setIsVisible}
					setIsCalendarVisible={setIsCalendarVisible}
				/>
				{/* NOTE: добавлена проверка на загрузку, ее в будущем можно использовать для отображения состояния подгрузки контента */}
				{!eventListLoading && events?.length != 0 ? (
					<>
						<div className='EventList_events mt-16 px-16'>
							{events?.map((event, index, eventsArray) => (
								<>
									{/* {index === 0 && (
										<h1 className='text-18 medium mt-16 mb-16'>
											{new Date(event.start_at).toLocaleDateString('ru-RU')}
										</h1>
									)} */}
									<EventCard event={event} wishlist={wishlist} refreshWishlist={refreshWishlist}/>
								</>
							))}
						</div>
					</>
				) : (
					<div className='EventList_noEvents'>
						<img
							src={torus}
							alt='torus'
						/>
						<h1 className='text-28 ln-30 medium'>Ни одного мероприятия не найдено</h1>
					</div>
				)}
				<Navbar />
				<AnimatePresence>
					{/* // TODO: Компонент ModalFilterInterests не подрузамевал возможность currentTagGroup undefiend  */}
					{isVisible && currentTagGroup && (
						<ModalFilterInterests
							// TODO: Неверная типизация или условие
							tagGroup={currentTagGroup}
							tags={tags}
							filters={filters}
							setIsVisible={setIsVisible}
							setFilters={setFilters}
						/>
					)}
					{isCalendarVisible && (
						<CustomCalendar
							setVisible={setIsCalendarVisible}
							setFilterDates={setFilterDates}
							filterDates={filterDates}
						/>
					)}
				</AnimatePresence>
			</div>
		</PageLayout>
	);
};

export default EventList;
