import { EnvConfig } from '@/app/config';
import { ApiConfig } from '@/shared/api/gen/http-client';
import { Url } from '@/shared/api/gen/Url';
import { Tags } from '@/shared/api/gen/Tags';
import { Users } from '@/shared/api/gen/Users';
import { Uploads } from '@/shared/api/gen/Uploads';
import {Events} from "@/shared/api/gen/Events";
import {TagGroups} from "@/shared/api/gen/TagGroups";

const apiStore = (apiConfig?: ApiConfig<unknown>) => ({
	tags: new Tags(apiConfig),
	users: new Users(apiConfig),
	uploads: new Uploads(apiConfig),
	events: new Events(apiConfig),
	tagGroups: new TagGroups(apiConfig),
});

const api = apiStore({
	baseUrl: EnvConfig.HOST_URL,
});

export default api;
