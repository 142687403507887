import React from 'react';
import './index.scss';
import house from 'public/images/house.png';
import calendar from 'public/images/calendar_fold.png';
import favourites from 'public/images/favourites.png';
import user from 'public/images/user.png';
import { useNavigate } from 'react-router-dom';

const NavElement = ({ text, icon, onClick }: { text: string; icon: string; onClick: () => void }) => {
	return (
		<div
			className='Navbar_element'
			onClick={onClick}>
			<img
				src={icon}
				alt='nav_icon'
			/>
			<span className='text-10 semibold'>{text}</span>
		</div>
	);
};

const Navbar = () => {
	const navigate = useNavigate();
	return (
		<div className='Navbar'>
			<NavElement
				icon={house}
				onClick={() => navigate('/')}
				text='Главная'
			/>
			<NavElement
				icon={calendar}
				onClick={() => navigate('/events')}
				text='Мероприятия'
			/>
			<NavElement
				icon={favourites}
				onClick={() => navigate('/favourites')}
				text='Избранное'
			/>
			<NavElement
				icon={user}
				onClick={() => navigate('/profile')}
				text='Профиль'
			/>
		</div>
	);
};

export default Navbar;